import {
  Add_REWARD,
  Add_REWARD_SUCCESS,
  Add_REWARD_API_ERROR,
  View_REWARD,
  View_REWARD_BYID,
  View_REWARD_API_ERROR,
  View_REWARD_BYID_API_ERROR,
  View_REWARD_BYID_SUCCESS,
  View_REWARD_SUCCESS,
} from "./actionTypes"

export const addUpdateReward = user => {
  debugger
  return {
    type: Add_REWARD,
    payload: { user },
  }
}

export const addRewardSucess = user => {
  return {
    type: Add_REWARD_SUCCESS,
    payload: user,
  }
}

export const rewardApiError = error => {
  return {
    type: Add_REWARD_API_ERROR,
    payload: error,
  }
}

export const allReward = () => {
  debugger
  return {
    type: View_REWARD,
  }
}

export const viewRewardSucess = rewardData => {
  return {
    type: View_REWARD_SUCCESS,
    payload: rewardData,
  }
}

export const viewRewardApiError = error => {
  return {
    type: View_REWARD_API_ERROR,
    payload: error,
  }
}

export const rewardById = rewardId => {
  debugger
  return {
    type: View_REWARD_BYID,
    payload: { rewardId },
  }
}

export const rewardSucessByID = rewardData => {
  return {
    type: View_REWARD_BYID_SUCCESS,
    payload: rewardData,
  }
}

export const rewardByIDApiError = error => {
  return {
    type: View_REWARD_BYID_API_ERROR,
    payload: error,
  }
}

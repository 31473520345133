import { call, put, takeEvery, select } from "redux-saga/effects"

import { Add_REWARD, View_REWARD, View_REWARD_BYID } from "./actionTypes"
import {
  addRewardSucess,
  rewardApiError,
  rewardSucessByID,
  rewardByIDApiError,
  viewRewardSucess,
  viewRewardApiError,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
let obj = JSON.parse(localStorage.getItem("authUser"))

function* addUpdateReward({ payload: { user } }) {
  debugger

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
      },
    }

    const response = yield call(axios.post, url.addUpdateReward, user, config)
    if (response.status == 200) {
      yield put(addRewardSucess(response.data))
      window.location.href = "/reward/allReward"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(rewardApiError(errorMessage))
  }
}

function* fetchAllReward() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    console.log("Config", config)
    const response = yield call(axios.get, url.getAllRewardUrl, config)
    console.log("Allcom", response)
    yield put(viewRewardSucess(response.data))

    //yield put(viewCompanySucess(response.data))
  } catch (error) {
    yield put(viewRewardApiError(error))
  }
}

function* fetchrewardByID({ payload: { rewardId } }) {
  try {
    console.log("ComapnyDataInFun", rewardId)
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.get,
      `${url.getRewardById}/${rewardId}`,
      config,
    )
    console.log("Allcom", response)
    yield put(rewardSucessByID(response.data))
  } catch (error) {
    yield put(rewardByIDApiError(error))
  }
}

function* LoyaltySaga() {
  yield takeEvery(Add_REWARD, addUpdateReward)
  yield takeEvery(View_REWARD, fetchAllReward)
  yield takeEvery(View_REWARD_BYID, fetchrewardByID)
}

export default LoyaltySaga

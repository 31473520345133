import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Kanban from "pages/Kanban"

//Master
import MasterStringMap from "../pages/Master/StringMap"
import MasterStringMap2 from "../pages/Master/StringMap2"

// Comppany
import AddCompany from "../pages/Company/AddCompany"
import AllCompany from "../pages/Company/Company"
import ViewCompany from "../pages/Company/ViewCompany"

// user
import AllUser from "../pages/User/User"
import ViewUser from "../pages/User/ViewUser"

// Dealer
import AddDealer from "../pages/Dealer/AddDealer"
import AllDealer from "../pages/Dealer/Dealer"
import ViewDealer from "../pages/Dealer/ViewDealer"

// Product
import AddProduct from "../pages/Product/AddProduct"
import AllProduct from "../pages/Product/Product"
import ViewProduct from "../pages/Product/ViewProduct"

// Loyalty
import AddLoyalty from "../pages/loyaltiyPoints/AddLoyalty.js"
import AllLoyalty from "../pages/loyaltiyPoints/Loyalty.js"
import ViewLoyalty from "../pages/loyaltiyPoints/ViewLoyalty.js"

// reward
import AddReward from "../pages/Reward/AddReward"
import AllReward from "../pages/Reward/Reward"
import Viewreward from "../pages/Reward/Viewreward"

// order
import AddOrder from "../pages/Order/AddOrder"
import AllOrder from "../pages/Order/Order"
import ViewOrder from "../pages/Order/ViewOrder"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  { path: "/kanbanboard", component: <Kanban /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  //StringMap
  { path: "/Master/Stringmap", component: <MasterStringMap /> },
  { path: "/Master/Stringmap2", component: <MasterStringMap2 /> },

  // Company
  { path: "/Company/AddCompany", component: <AddCompany /> },
  { path: "/Company/allCompany", component: <AllCompany /> },
  { path: "/Company/view/:CompanyId", component: <ViewCompany /> },

  // user
  { path: "/user/allUser", component: <AllUser /> },
  { path: "/user/view/:UserId", component: <ViewUser /> },

  // Dealer
  { path: "/Dealer/AddDealer", component: <AddDealer /> },
  { path: "/Dealer/allDealer", component: <AllDealer /> },
  { path: "/Dealer/view/:DealerId", component: <ViewDealer /> },

  // product
  { path: "/Product/AddProduct", component: <AddProduct /> },
  { path: "/Product/allProduct", component: <AllProduct /> },
  { path: "/Product/view/:ProductId", component: <ViewProduct /> },

  // loyalty
  { path: "/Loyalty/AddLoyalty", component: <AddLoyalty /> },
  { path: "/Loyalty/allLoyalty", component: <AllLoyalty /> },
  { path: "/Loyalty/view/:LoyaltyPointId", component: <ViewLoyalty /> },

  // reward
  { path: "/reward/AddReward", component: <AddReward /> },
  { path: "/reward/allReward", component: <AllReward /> },
  { path: "/reward/view/:rewardId", component: <Viewreward /> },

  // Order
  { path: "/order/AddOrder", component: <AddOrder /> },
  { path: "/order/allOrder", component: <AllOrder /> },
  { path: "/order/view/:OrderId", component: <ViewOrder /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }

import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import tasksSaga from "./tasks/saga"
import companySaga from "./company/saga"
import ProductSaga from "./product/saga"
import LoyaltySaga from "./loyaltiy/saga"
import RewardSaga from "./reward/saga"
import dealerSaga from "./Dealer/saga"
import OrderSaga from "./order/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(calendarSaga),
    fork(tasksSaga),
    fork(tasksSaga),
    fork(companySaga),
    fork(ProductSaga),
    fork(LoyaltySaga),
    fork(RewardSaga),
    fork(dealerSaga),
    fork(OrderSaga),
  ])
}

export const Add_REWARD = "Add_REWARD"
export const Add_REWARD_SUCCESS = "Add_REWARD_SUCCESS"
export const Add_REWARD_API_ERROR = "Add_REWARD_API_ERROR"

export const View_REWARD = "View_REWARD"
export const View_REWARD_SUCCESS = "View_REWARD_SUCCESS"
export const View_REWARD_API_ERROR = "View_REWARD_API_ERROR"

export const View_REWARD_BYID = "View_REWARD_BYID"
export const View_REWARD_BYID_SUCCESS = "View_REWARD_BYID_SUCCESS"
export const View_REWARD_BYID_API_ERROR = "View_REWARD_BYID_API_ERROR"

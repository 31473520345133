import {
  Add_REWARD,
  Add_REWARD_API_ERROR,
  Add_REWARD_SUCCESS,
  View_REWARD_API_ERROR,
  View_REWARD_SUCCESS,
  View_REWARD_BYID,
  View_REWARD_BYID_API_ERROR,
  View_REWARD_BYID_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  rewardData: [],
}

const Reward = (state = initialState, action) => {
  switch (action.type) {
    case Add_REWARD:
      state = {
        ...state,
        loading: true,
      }
      break
    case Add_REWARD_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case Add_REWARD_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_REWARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyData: action.payload,
      }
      break
    case View_REWARD_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case View_REWARD_BYID:
      state = {
        ...state,
        loading: true,
      }
      break

    case View_REWARD_BYID_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyById: action.payload,
      }
      break
    case View_REWARD_BYID_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Reward

import {
  Add_ORDER,
  Add_ORDER_API_ERROR,
  Add_ORDER_SUCCESS,
  View_ORDER_API_ERROR,
  View_ORDER_BYID,
  View_ORDER_BYID_API_ERROR,
  View_ORDER_BYID_SUCCESS,
  View_ORDER_SUCCESS,
  ALL_LOYAL_PRODUCT_SUCCESS,
  ALL_LOYAL_PRODUCT_API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  orderData: [],
}

const Order = (state = initialState, action) => {
  switch (action.type) {
    case Add_ORDER:
      state = {
        ...state,
        loading: true,
      }
      break
    case Add_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case Add_ORDER_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        orderData: action.payload,
      }
      break
    case View_ORDER_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case ALL_LOYAL_PRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        productData: action.payload,
      }
      break
    case ALL_LOYAL_PRODUCT_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case View_ORDER_BYID:
      state = {
        ...state,
        loading: true,
      }
      break
    case View_ORDER_BYID_SUCCESS:
      state = {
        ...state,
        loading: false,
        OrderById: action.payload,
      }
      break
    case View_ORDER_BYID_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Order

import {
  Add_Company,
  Add_Company_API_ERROR,
  Add_Company_SUCCESS,
  View_Company_SUCCESS,
  View_Company_API_ERROR,
  View_Company_BYID,
  View_Company_BYID_API_ERROR,
  View_Company_BYID_SUCCESS,
  View_USER_API_ERROR,
  View_USER_SUCCESS,
  View_USER_BYID,
  View_USER_BYID_SUCCESS,
  View_USER_BYID_API_ERROR,
  ADDUPDATE_USER,
  ADDUPDATE_USER_API_ERROR,
  ADDUPDATE_USER_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  companyData: [],
}

const addCompany = (state = initialState, action) => {
  switch (action.type) {
    case Add_Company:
      state = {
        ...state,
        loading: true,
      }
      break
    case Add_Company_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case Add_Company_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_Company_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyData: action.payload,
      }
      break
    case View_Company_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case View_Company_BYID:
      state = {
        ...state,
        loading: true,
      }
      break
    case View_Company_BYID_SUCCESS:
      state = {
        ...state,
        loading: false,
        companyById: action.payload,
      }
      break
    case View_Company_BYID_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        userData: action.payload,
      }
      break
    case View_USER_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case View_USER_BYID:
      state = {
        ...state,
        loading: true,
      }
      break
    case View_USER_BYID_SUCCESS:
      state = {
        ...state,
        loading: false,
        userByID: action.payload,
      }
      break
    case View_USER_BYID_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case ADDUPDATE_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADDUPDATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADDUPDATE_USER_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default addCompany

import {
  Add_Dealer,
  Add_Dealer_API_ERROR,
  Add_Dealer_SUCCESS,
  View_Dealer_SUCCESS,
  View_Dealer_API_ERROR,
  View_Dealer_BYID,
  View_Dealer_BYID_API_ERROR,
  View_Dealer_BYID_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  dealerData: [],
}

const addDealer = (state = initialState, action) => {
  switch (action.type) {
    case Add_Dealer:
      state = {
        ...state,
        loading: true,
      }
      break
    case Add_Dealer_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case Add_Dealer_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case View_Dealer_SUCCESS:
      state = {
        ...state,
        loading: false,
        dealerData: action.payload,
      }
      break
    case View_Dealer_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case View_Dealer_BYID:
      state = {
        ...state,
        loading: true,
      }
      break
    case View_Dealer_BYID_SUCCESS:
      state = {
        ...state,
        loading: false,
        dealerById: action.payload,
      }
      break
    case View_Dealer_BYID_API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default addDealer

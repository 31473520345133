export const Add_ORDER = "Add_ORDER"
export const Add_ORDER_API_ERROR = "Add_ORDER_API_ERROR"
export const Add_ORDER_SUCCESS = "Add_ORDER_SUCCESS"

export const View_ORDER = "View_ORDER"
export const View_ORDER_API_ERROR = "View_ORDER_API_ERROR"
export const View_ORDER_SUCCESS = "View_ORDER_SUCCESS"

export const View_ORDER_BYID = "View_ORDER_BYID"
export const View_ORDER_BYID_API_ERROR = "View_ORDER_BYID_API_ERROR"
export const View_ORDER_BYID_SUCCESS = "View_ORDER_BYID_SUCCESS"

export const ALL_LOYAL_PRODUCT = "ALL_LOYAL_PRODUCT"
export const ALL_LOYAL_PRODUCT_API_ERROR = "ALL_LOYAL_PRODUCT_API_ERROR"
export const ALL_LOYAL_PRODUCT_SUCCESS = "ALL_LOYAL_PRODUCT_SUCCESS"

import {
  Add_Dealer,
  Add_Dealer_API_ERROR,
  Add_Dealer_SUCCESS,
  View_Dealer,
  View_Dealer_SUCCESS,
  View_Dealer_API_ERROR,
  View_Dealer_BYID,
  View_Dealer_BYID_SUCCESS,
  View_Dealer_BYID_API_ERROR,
} from "./actionTypes"

export const addUpdateDealer = user => {
  debugger
  return {
    type: Add_Dealer,
    payload: { user },
  }
}

export const addDealerSucess = user => {
  return {
    type: Add_Dealer_SUCCESS,
    payload: user,
  }
}

export const dealerApiError = error => {
  return {
    type: Add_Dealer_API_ERROR,
    payload: error,
  }
}

export const allDealer = () => {
  debugger
  return {
    type: View_Dealer,
  }
}

export const viewDealerSucess = dealerData => {
  return {
    type: View_Dealer_SUCCESS,
    payload: dealerData,
  }
}

export const viewDealerApiError = error => {
  return {
    type: View_Dealer_API_ERROR,
    payload: error,
  }
}

export const DealerById = dealerId => {
  debugger
  return {
    type: View_Dealer_BYID,
    payload: { dealerId },
  }
}

export const DealerSucessByID = dealerData => {
  return {
    type: View_Dealer_BYID_SUCCESS,
    payload: dealerData,
  }
}

export const DealerByIDApiError = error => {
  return {
    type: View_Dealer_BYID_API_ERROR,
    payload: error,
  }
}

import React, { useState, useEffect } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  CardImg,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateReward, rewardById } from "../../store/actions"
import { useParams, useNavigate } from "react-router-dom"

const ViewReward = props => {
  document.title = "View Company"
  const { rewardId } = useParams()
  const [editContact, setEditContact] = useState(false)
  const [images, setImages] = useState([])
  const companyData = useSelector(
    state => state.reward.companyById && state.reward.companyById.data,
  )
  const [rewardData, setData] = useState()
  const breadcrumbItems = [
    // { title: "Cognisun", link: "#" },
    // { title: "StringMap", link: "#" },
    // { title: "StringMap1", link: "#" },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const handleImageChange = e => {
    const files = Array.from(e.target.files)
    setImages(files)
  }

  useEffect(() => {
    props.setBreadcrumbItems(
      editContact ? "Edit Reward" : "View Reward",
      breadcrumbItems,
    )
    const getCompanyDataById = async rewardId => {
      try {
        await dispatch(rewardById(rewardId))
      } catch (error) {
        console.log(error)
      }
    }
    getCompanyDataById(rewardId)
  }, [rewardId])

  useEffect(() => {
    console.log("CompanyData", companyData)

    if (companyData && companyData[0].length > 0) {
      setValue("RewardId", companyData && companyData[0][0].RewardId)
      setValue("RewardName", companyData && companyData[0][0].RewardName)
      setValue(
        "RewardMarketPrice",
        companyData && companyData[0][0].RewardMarketPrice,
      )
      setValue(
        "RequiredPoints",
        companyData && companyData[0][0].RequiredPoints,
      )
      setValue(
        "RewardStartDate",
        companyData && companyData[0][0].RewardStartDate,
      )
      setValue("RewardEndDate", companyData && companyData[0][0].RewardEndDate)
      setData(companyData && companyData[0][0])
    }
  }, [companyData, setValue])

  const handleInputChange = e => {
    const { name, value } = e.target
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
    setValue(name, value)
  }

  const onSubmit = async data => {
    try {
      console.log("Data", data)
      const formData = new FormData()
      images.forEach(image => {
        if (image instanceof File) {
          formData.append("file", image)
        } else {
          console.error("Image is not a File:", image)
        }
      })
      formData.append("RewardId", data.RewardId)
      formData.append("CompanyId", rewardData.CompanyId)
      formData.append("RewardNumber", rewardData.RewardNumber)
      // formData.append("AttachmentId", rewardData.AttachmentId)
      // formData.append("AttachmentType", rewardData.AttachmentType)
      // formData.append("ReferenceType", rewardData.ReferenceType)
      // formData.append("ReferenceId", rewardData.ReferenceId)
      // formData.append("Directory", rewardData.Directory)
      formData.append("Filename", rewardData.Filename)
      // formData.append("Type", rewardData.Type)
      formData.append("URL", rewardData.URL)
      // formData.append("FileSize", rewardData.FileSize)
      formData.append("RewardName", data.RewardName)
      formData.append("RewardMarketPrice", data.RewardMarketPrice)
      formData.append("RequiredPoints", data.RequiredPoints)
      formData.append("RewardStartDate", data.RewardStartDate)
      formData.append("RewardEndDate", data.RewardEndDate)
      formData.append("OperationType", "U")
      const response = await dispatch(addUpdateReward(formData))
      console.log("Res", response)
      if (response.type == "Add_REWARD") {
        toast.success("Reward Update Successfully")
      } else {
        console.error("Failed to add/update Reward:", response)
        toast.error("Failed to add/update Reward.")
      }
    } catch (error) {
      console.error("Failed to add/update Reward:", error)
      toast.error("Failed to add/update Reward.")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="mb-10">
                Reward Info
              </CardTitle>

              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="RewardName">Reward Name *</Label>
                      <input
                        type="text"
                        {...register("RewardName", { required: true })}
                        placeholder="Enter Reward Name "
                        className="form-control"
                        disabled={!editContact}
                        onChange={handleInputChange}
                        value={rewardData && rewardData.RewardName}
                      />
                      <span className="text-danger">
                        {errors.RewardName && "Reward Name is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward Market Price</Label>
                      <input
                        className="form-control"
                        name="RewardMarketPrice"
                        type="number"
                        {...register("RewardMarketPrice", { required: true })}
                        placeholder="Enter Reward Market Price "
                        disabled={!editContact}
                        onChange={handleInputChange}
                        value={rewardData && rewardData.RewardMarketPrice}
                      />
                      <span className="text-danger">
                        {errors.RewardMarketPrice &&
                          "Reward Market Price is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward EndDate *</Label>
                      <input
                        className="form-control"
                        name="RewardEndDate"
                        placeholder="Enter Reward EndDate"
                        type="date"
                        {...register("RewardEndDate", {
                          required: true,
                        })}
                        disabled={!editContact}
                        onChange={handleInputChange}
                        value={rewardData && rewardData.RewardEndDate}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.RewardEndDate && "End Date is required."}
                    </span>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Required Points *</Label>
                      <input
                        className="form-control"
                        name="RequiredPoints"
                        type="number"
                        placeholder="Enter Required Points"
                        {...register("RequiredPoints", {
                          required: true,
                        })}
                        disabled={!editContact}
                        onChange={handleInputChange}
                        value={rewardData && rewardData.RequiredPoints}
                      />
                      <span className="text-danger">
                        {errors.RequiredPoints && "RequiredPoints is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward StartDate *</Label>
                      <input
                        className="form-control"
                        name="RewardStartDate"
                        type="date"
                        placeholder="Enter Reward StartDate"
                        {...register("RewardStartDate", {
                          required: true,
                        })}
                        disabled={!editContact}
                        onChange={handleInputChange}
                        value={rewardData && rewardData.RewardStartDate}
                      />
                      <span className="text-danger">
                        {errors.RewardStartDate && "StartDate is required."}
                      </span>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label>Reward Image *</Label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </FormGroup> */}
                  </Col>
                  <Col>
                    <CardImg
                      className="cursor-pointer"
                      top
                      width="40%"
                      height="40%"
                      src={rewardData && rewardData.URL}
                      alt={rewardData && rewardData.Filename}
                    />
                  </Col>
                </Row>

                <FormGroup className="mb-0">
                  <div className="d-flex justify-content-end mt-4 gap-2">
                    {editContact ? (
                      <>
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={() => setEditContact(false)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary" className="ms-1">
                          Update
                        </Button>
                      </>
                    ) : (
                      <Button color="info" onClick={() => setEditContact(true)}>
                        Edit
                      </Button>
                    )}
                  </div>

                  {/* <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div> */}
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ViewReward)

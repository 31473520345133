import { call, put, takeEvery, select } from "redux-saga/effects"

import {
  Add_Company,
  View_Company,
  View_Company_BYID,
  View_USER,
  ADDUPDATE_USER,
  View_USER_BYID,
} from "./actionTypes"
import {
  addCompanySucess,
  compnayApiError,
  viewCompanySucess,
  viewCompanyApiError,
  CompanySucessByID,
  CompanyByIDApiError,
  addUpdateUserSucess,
  addUpdateUserApiError,
  userSuccess,
  userApiError,
  userByIdSuccess,
  userByIDError,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
let obj = JSON.parse(localStorage.getItem("authUser"))

function* addUpdateCompany({ payload: { user } }) {
  debugger

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(axios.post, url.addUpdateCompany, user, config)
    if (response.status == 200) {
      yield put(addCompanySucess(response.data))
      window.location.href = "/Company/allCompany"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(compnayApiError(errorMessage))
  }
}

function* fetchAllCompany() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(axios.get, url.getAllCompany, config)
    console.log("Allcom", response)
    yield put(viewCompanySucess(response.data))

    //yield put(viewCompanySucess(response.data))
  } catch (error) {
    yield put(viewCompanyApiError(error))
  }
}

function* fetchCompanyByID({ payload: { companyId } }) {
  try {
    console.log("ComapnyDataInFun", companyId)
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.get,
      `${url.getCompanyById}/${companyId}`,
      config,
    )
    console.log("Allcom", response)
    yield put(CompanySucessByID(response.data))
  } catch (error) {
    yield put(CompanyByIDApiError(error))
  }
}

function* addUpdateUser({ payload: { user } }) {
  debugger

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(axios.post, url.addUpdateUser, user, config)
    if (response.status == 200) {
      yield put(addUpdateUserSucess(response.data))
      window.location.href = "/user/allUser"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(addUpdateUserApiError(errorMessage))
  }
}

function* fetchAllUser() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(axios.get, url.getAllUser, config)
    yield put(userSuccess(response.data))
  } catch (error) {
    yield put(userApiError(error))
  }
}

function* fetchUserByID({ payload: { userId } }) {
  try {
    console.log("ComapnyDataInFun", userId)
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.get,
      `${url.getUserByID}/${userId}`,
      config,
    )
    yield put(userByIdSuccess(response.data))
  } catch (error) {
    yield put(userByIDError(error))
  }
}

function* companySaga() {
  yield takeEvery(Add_Company, addUpdateCompany)
  yield takeEvery(View_Company, fetchAllCompany)
  yield takeEvery(View_Company_BYID, fetchCompanyByID)
  yield takeEvery(ADDUPDATE_USER, addUpdateUser)
  yield takeEvery(View_USER, fetchAllUser)
  yield takeEvery(View_USER_BYID, fetchUserByID)
}

export default companySaga

import React, { Fragment, useEffect, useState } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateReward } from "../../store/actions"
import { useNavigate } from "react-router-dom"

const AddReward = props => {
  // document.title =
  //   "StringMap | Cognisun - Responsive Bootstrap 5 Admin Dashboard"

  // const breadcrumbItems = [
  //   { title: "Cognisun", link: "#" },
  //   { title: "StringMap", link: "#" },
  //   { title: "StringMap1", link: "#" },
  // ]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [images, setImages] = useState([])
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const handleImageChange = e => {
    const files = Array.from(e.target.files)
    setImages(files)
  }

  useEffect(() => {}, [setValue])
  const onSubmit = async data => {
    try {
      if (images.length === 0) {
        toast.error("Please add Image")
        return
      }
      const formData = new FormData()
      images.forEach(image => {
        if (image instanceof File) {
          formData.append("file", image)
        } else {
          console.error("Image is not a File:", image)
        }
      })
      formData.append("RewardName", data.RewardName)
      formData.append("RewardMarketPrice", data.RewardMarketPrice)
      formData.append("RequiredPoints", data.RequiredPoints)
      formData.append("RewardStartDate", data.RewardStartDate)
      formData.append("RewardEndDate", data.RewardEndDate)
      formData.append("OperationType", "I")
      const response = await dispatch(addUpdateReward(formData))
      if (response.type == "Add_REWARD") {
        toast.success("Reward Add Successfully")
      } else {
        console.error("Failed to add/update Reward:", response)
        toast.error("Failed to add/update Reward.")
      }
    } catch (error) {
      console.error("Failed to add/update Reward:", error)
      toast.error("Failed to add/update Reward.")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="mb-10">
                Reward
              </CardTitle>

              <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="RewardName">Reward Name *</Label>
                      <input
                        type="text"
                        {...register("RewardName", { required: true })}
                        placeholder="Enter Reward Name "
                        className="form-control"
                      />
                      <span className="text-danger">
                        {errors.RewardName && "Reward Name is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward Market Price</Label>
                      <input
                        className="form-control"
                        name="RewardMarketPrice"
                        type="number"
                        {...register("RewardMarketPrice", { required: true })}
                        placeholder="Enter Reward Market Price "
                      />
                      <span className="text-danger">
                        {errors.RewardMarketPrice &&
                          "Reward Market Price is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward EndDate *</Label>
                      <input
                        className="form-control"
                        name="RewardEndDate"
                        placeholder="Enter Reward EndDate"
                        type="date"
                        {...register("RewardEndDate", {
                          required: true,
                        })}
                      />
                    </FormGroup>
                    <span className="text-danger">
                      {errors.RewardEndDate && "End Date is required."}
                    </span>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Required Points *</Label>
                      <input
                        className="form-control"
                        name="RequiredPoints"
                        type="number"
                        placeholder="Enter Required Points"
                        {...register("RequiredPoints", {
                          required: true,
                        })}
                      />
                      <span className="text-danger">
                        {errors.RequiredPoints && "RequiredPoints is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward StartDate *</Label>
                      <input
                        className="form-control"
                        name="RewardStartDate"
                        type="date"
                        placeholder="Enter Reward StartDate"
                        {...register("RewardStartDate", {
                          required: true,
                        })}
                      />
                      <span className="text-danger">
                        {errors.RewardStartDate && "StartDate is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reward Image *</Label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddReward)

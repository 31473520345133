import {
  Add_ORDER,
  Add_ORDER_API_ERROR,
  Add_ORDER_SUCCESS,
  View_ORDER,
  View_ORDER_API_ERROR,
  View_ORDER_BYID,
  View_ORDER_BYID_API_ERROR,
  View_ORDER_BYID_SUCCESS,
  View_ORDER_SUCCESS,
  ALL_LOYAL_PRODUCT,
  ALL_LOYAL_PRODUCT_API_ERROR,
  ALL_LOYAL_PRODUCT_SUCCESS,
} from "./actionTypes"

export const addUpdateOrder = user => {
  debugger
  return {
    type: Add_ORDER,
    payload: { user },
  }
}

export const addUpdateOrderSucess = user => {
  return {
    type: Add_ORDER_SUCCESS,
    payload: user,
  }
}

export const orderApiError = error => {
  return {
    type: Add_ORDER_API_ERROR,
    payload: error,
  }
}

export const allOrder = () => {
  debugger
  return {
    type: View_ORDER,
  }
}

export const viewOrderSucess = orderData => {
  return {
    type: View_ORDER_SUCCESS,
    payload: orderData,
  }
}

export const viewOrderApiError = error => {
  return {
    type: View_ORDER_API_ERROR,
    payload: error,
  }
}

export const allProductWithLoyaltyPoint = () => {
  debugger
  return {
    type: ALL_LOYAL_PRODUCT,
  }
}

export const productWithLoyaltyPointSucess = productData => {
  return {
    type: ALL_LOYAL_PRODUCT_SUCCESS,
    payload: productData,
  }
}

export const productWithLoyaltyPointError = error => {
  return {
    type: ALL_LOYAL_PRODUCT_API_ERROR,
    payload: error,
  }
}

export const OrderById = orderId => {
  debugger
  return {
    type: View_ORDER_BYID,
    payload: { orderId },
  }
}

export const OrderSucessByID = companyData => {
  return {
    type: View_ORDER_BYID_SUCCESS,
    payload: companyData,
  }
}

export const OrderByIDApiError = error => {
  return {
    type: View_ORDER_BYID_API_ERROR,
    payload: error,
  }
}

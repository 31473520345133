import { call, put, takeEvery, select } from "redux-saga/effects"

import { Add_Dealer, View_Dealer, View_Dealer_BYID } from "./actionTypes"
import {
  addDealerSucess,
  dealerApiError,
  viewDealerSucess,
  viewDealerApiError,
  DealerSucessByID,
  DealerByIDApiError,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
let obj = JSON.parse(localStorage.getItem("authUser"))
function* addUpdateDealer({ payload: { user } }) {
  debugger

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }

    const response = yield call(axios.post, url.addUpdateDealer, user, config)
    if (response.status == 200) {
      yield put(addDealerSucess(response.data))
      window.location.href = "/Dealer/allDealer"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(dealerApiError(errorMessage))
  }
}

function* fetchAllDealer() {
  debugger
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(axios.get, url.getAllDealer, config)
    console.log("Allcom", response)
    yield put(viewDealerSucess(response.data))

    //yield put(viewDealerSucess(response.data))
  } catch (error) {
    yield put(viewDealerApiError(error))
  }
}

function* fetchDealerByID({ payload: { dealerId } }) {
  debugger
  try {
    console.log("ComapnyDataInFun", dealerId)
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(
      axios.get,
      `${url.getDealerByID}/${dealerId}`,
      config,
    )
    console.log("Allcom", response)
    yield put(DealerSucessByID(response.data))
  } catch (error) {
    yield put(DealerByIDApiError(error))
  }
}

function* dealerSaga() {
  yield takeEvery(Add_Dealer, addUpdateDealer)
  yield takeEvery(View_Dealer, fetchAllDealer)
  yield takeEvery(View_Dealer_BYID, fetchDealerByID)
}

export default dealerSaga

export const Add_Dealer = "Add_Dealer"
export const Add_Dealer_SUCCESS = "Add_Dealer_SUCCESS"
export const Add_Dealer_API_ERROR = "Add_Dealer_API_ERROR"

export const View_Dealer = "View_Dealer"
export const View_Dealer_SUCCESS = "View_Dealer_SUCCESS"
export const View_Dealer_API_ERROR = "View_Dealer_API_ERROR"

export const View_Dealer_BYID = "View_Dealer_BYID"
export const View_Dealer_BYID_SUCCESS = "View_Dealer_BYID_SUCCESS"
export const View_Dealer_BYID_API_ERROR = "View_Dealer_BYID_API_ERROR"

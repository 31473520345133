export const Add_Company = "Add_Company"
export const Add_Company_SUCCESS = "Add_Company_SUCCESS"
export const Add_Company_API_ERROR = "Add_Company_API_ERROR"

export const View_Company = "View_Company"
export const View_Company_SUCCESS = "View_Company_SUCCESS"
export const View_Company_API_ERROR = "View_Company_API_ERROR"

export const View_Company_BYID = "View_Company_BYID"
export const View_Company_BYID_SUCCESS = "View_Company_BYID_SUCCESS"
export const View_Company_BYID_API_ERROR = "View_Company_BYID_API_ERROR"

export const View_USER = "View_USER"
export const View_USER_SUCCESS = "View_USER_SUCCESS"
export const View_USER_API_ERROR = "View_USER_API_ERROR"

export const View_USER_BYID = "View_USER_BYID"
export const View_USER_BYID_SUCCESS = "View_USER_BYID_SUCCESS"
export const View_USER_BYID_API_ERROR = "View_USER_BYID_API_ERROR"

export const ADDUPDATE_USER = "ADDUPDATE_USER"
export const ADDUPDATE_USER_SUCCESS = "ADDUPDATE_USER_SUCCESS"
export const ADDUPDATE_USER_API_ERROR = "ADDUPDATE_USER_API_ERROR"

import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const StringMap = (props) => {
  document.title = "StringMap | Cognisun - Responsive Bootstrap 5 Admin Dashboard";

  const breadcrumbItems = [
    { title: "Cognisun", link: "#" },
    { title: "StringMap", link: "#" },
    { title: "StringMap1", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems('StringMap1', breadcrumbItems)
  })

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Validation type</CardTitle>
              <p className="card-title-desc">
                Parsley is a availity reactstrap validation. It helps you
                provide your users with feedback on their form submission
                before sending it to your server.
                  </p>

              <AvForm>
                <AvField
                  className="mb-3"
                  name="username"
                  label="Required  "
                  placeholder="Type Something"
                  type="text"
                  errorMessage="Enter Name"
                  validate={{ required: { value: true } }}
                />
                <Label>Equal To</Label>
                <AvField
                  className="mb-3"
                  name="password"
                  type="password"
                  placeholder="Password"
                  errorMessage="Enter password"
                  validate={{ required: { value: true } }}
                />
                <AvField
                  className="mb-3"
                  name="password1"
                  type="password"
                  placeholder="Re-type Password"
                  errorMessage="Enter Re-password"
                  validate={{
                    required: { value: true },
                    match: { value: "password" },
                  }}
                />
                <AvField
                  className="mb-3"
                  name="email"
                  label="E-Mail  "
                  placeholder="Enter Valid Email"
                  type="email"
                  errorMessage="Invalid Email"
                  validate={{
                    required: { value: true },
                    email: { value: true },
                  }}
                />
                <AvField
                  className="mb-3"
                  name="digits"
                  label="Digits  "
                  placeholder="Enter Only Digits"
                  type="number"
                  errorMessage="Enter Only Digits"
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Only Digits",
                    },
                  }}
                />
                <AvField
                  className="mb-3"
                  name="number"
                  label="Number  "
                  placeholder="Enter Only number"
                  type="number"
                  errorMessage="Enter Only Number"
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Only Numbers",
                    },
                  }}
                />
                <AvField
                  className="mb-3"
                  name="alphanumeric"
                  label="Alphanumeric  "
                  placeholder="Enter Only alphanumeric value"
                  type="text"
                  errorMessage="Enter Only Alphanumeric"
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[0-9a-zA-Z]+$",
                      errorMessage: "Only Alphanumeric",
                    },
                  }}
                />
                <AvField
                  className="mb-3"
                  type="textarea"
                  label="Textarea "
                  name="address"
                  id="address"
                  rows="5"
                  placeholder="Address"
                  errorMessage="This value is required."
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[0-9a-zA-Z]+$",
                      errorMessage: "Only Alphanumeric",
                    },
                  }}
                />
                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                        </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                        </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(StringMap);
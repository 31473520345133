import { call, put, takeEvery, select } from "redux-saga/effects"

import {
  Add_ORDER,
  View_ORDER,
  View_ORDER_BYID,
  ALL_LOYAL_PRODUCT,
} from "./actionTypes"
import {
  addUpdateOrderSucess,
  orderApiError,
  viewOrderSucess,
  viewOrderApiError,
  OrderSucessByID,
  OrderByIDApiError,
  productWithLoyaltyPointSucess,
  productWithLoyaltyPointError,
} from "./actions"
import axios from "axios"
import * as url from "../../helpers/url_helper"
let obj = JSON.parse(localStorage.getItem("authUser"))

function* addUpdateOrders({ payload: { user } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
      },
    }
    const response = yield call(axios.post, url.addUpdateOrder, user, config)
    if (response.status == 200) {
      yield put(addUpdateOrderSucess(response.data))
      window.location.href = "/order/AddOrder"
      // history("/dashboard")
    } else {
      throw new Error("Unexpected response status")
    }
  } catch (err) {
    const errorMessage =
      err.response.data?.error || err.response.statusText || "An error occurred"
    yield put(orderApiError(errorMessage))
  }
}

function* fetchAllOrder() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const now = new Date()
    const fromDate = new Date(now.getFullYear(), now.getMonth(), 1) // First day of the current month
    const toDate = now // Current date and time
    console.log("Config", config)
    const response = yield call(
      axios.post,
      url.getAllOrder,
      { FromDate: fromDate, ToDate: toDate },
      config,
    )
    console.log("Allcom", response)
    yield put(viewOrderSucess(response.data))
  } catch (error) {
    yield put(viewOrderApiError(error))
  }
}

function* fetchOrderById({ payload: { orderId } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    console.log("Configpackging", config)
    console.log("Configpackging", orderId)
    const response = yield call(
      axios.get,
      `${url.getOrderById}/${orderId}`,
      config,
    )
    console.log("Allcom", response)
    yield put(OrderSucessByID(response.data))
  } catch (error) {
    yield put(OrderByIDApiError(error))
  }
}

function* fetchAllProductWithLoyaltyPoints() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${obj.accessToken}`,
        "Content-Type": "application/json",
      },
    }
    const response = yield call(axios.get, url.getAllProduct, config)
    console.log("ProductLoyaltypoints", response)
    yield put(productWithLoyaltyPointSucess(response.data))
  } catch (error) {
    yield put(productWithLoyaltyPointError(error))
  }
}

function* OrderSaga() {
  yield takeEvery(Add_ORDER, addUpdateOrders)
  yield takeEvery(View_ORDER, fetchAllOrder)
  yield takeEvery(View_ORDER_BYID, fetchOrderById)
  yield takeEvery(ALL_LOYAL_PRODUCT, fetchAllProductWithLoyaltyPoints)
}

export default OrderSaga

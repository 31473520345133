import React, { Fragment, useEffect, useState } from "react"
// useState
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  Label,
  // Input,
} from "reactstrap"
import { toast } from "react-toastify"
import { AvForm, input } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { addUpdateOrder, allProductWithLoyaltyPoint } from "../../store/actions"
import { useNavigate } from "react-router-dom"

const AddOrder = props => {
  document.title = "Add Order"

  const breadcrumbItems = [
    { title: "Cognisun", link: "#" },
    { title: "StringMap", link: "#" },
    { title: "StringMap1", link: "#" },
  ]
  const [cart, setCart] = useState([])
  const [totalPrice, setTotalPrice] = useState()
  const [totalPoints, setTotalPoints] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const orderData = useSelector(
    state => state.order.productData && state.order.productData.data,
  )
  let obj = JSON.parse(localStorage.getItem("authUser"))
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    getAllProductWithLoyaltyPoints()
  }, [setValue])

  const getAllProductWithLoyaltyPoints = async () => {
    try {
      await dispatch(allProductWithLoyaltyPoint())
    } catch (error) {
      console.log(error)
    }
  }

  const addToCart = product => {
    setCart(prevCart => {
      const existingProduct = prevCart.find(
        item => item.PackagingId === product.PackagingId,
      )
      if (existingProduct) {
        return prevCart.map(item =>
          item.PackagingId === product.PackagingId
            ? { ...item, quantity: item.quantity + 1 }
            : item,
        )
      } else {
        return [
          ...prevCart,
          {
            ...product,
            quantity: 1,
            EntityType: "Dealer",
            EntityId: obj.user.UserId,
            DealerId: "be227964-79c6-11ef-b986-5254b8c568f4",
            OrderPriority: "High",
          },
        ]
      }
    })
  }

  const removeFromCart = productId => {
    setCart(prevCart => prevCart.filter(item => item.PackagingId !== productId))
  }

  const getTotalPrice = () => {
    return cart.reduce(
      (total, item) => total + parseInt(item.Price) * item.quantity,
      0,
    )
    // setTotalPrice(price)
  }

  // Function to calculate total eligible points
  const getTotalPoints = () => {
    return cart.reduce(
      (total, item) => total + parseInt(item.EligiblePoints) * item.quantity,
      0,
    )
  }

  // Function to increment quantity
  const incrementQuantity = productId => {
    setCart(prevCart =>
      prevCart.map(item =>
        item.PackagingId === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item,
      ),
    )
  }

  // Function to decrement quantity
  const decrementQuantity = productId => {
    setCart(
      prevCart =>
        prevCart
          .map(item =>
            item.PackagingId === productId && item.quantity > 1
              ? { ...item, quantity: item.quantity - 1 }
              : item,
          )
          .filter(item => item.quantity > 0), // Remove items with 0 quantity
    )
  }

  const onSubmit = async data => {
    try {
      data = data.map(item => ({
        ...item,
        GrandTotal: getTotalPrice(),
        TotalEligiblePoint: getTotalPoints(),
        Amount: parseInt(item.Price) * item.quantity,
        EligiblePoint: parseInt(item.EligiblePoints) * item.quantity,
        Quantity: item.quantity,
      }))

      const response = await dispatch(
        addUpdateOrder({
          OrderItems: data,
          OperationType: "I",
        }),
      )
      if (response.type == "Add_ORDER") {
        toast.success("Order Add Successfully")
      } else {
        console.error("Failed to add/update Order:", response)
        toast.error("Failed to add/update Order.")
      }
    } catch (error) {
      console.error("Failed to add/update Order:", error)
      toast.error("Failed to add/update Order.")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              <div style={{ flex: 1, marginRight: "20px" }}>
                <div style={{ flex: 1 }}>
                  <CardTitle tag="h4" className="mb-10">
                    Products
                  </CardTitle>

                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Image
                        </th>
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Name
                        </th>
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Category
                        </th>
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Packaging
                        </th>
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Price
                        </th>
                        <th
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData &&
                        orderData[0].map(product => {
                          const inCart = cart.some(
                            item => item.PackagingId === product.PackagingId,
                          )
                          return (
                            <tr key={product.PackagingId}>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                <img
                                  src={product.URL}
                                  alt={product.Name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                {product.Name}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                {product.Category}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                {product.Packaging}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                ${product.Price}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                {inCart ? (
                                  <button
                                    disabled
                                    style={{
                                      backgroundColor: "#ddd",
                                      color: "gray",
                                      border: "none",
                                      padding: "5px 10px",
                                      borderRadius: "3px",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    Added
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => addToCart(product)}
                                    style={{
                                      backgroundColor: "#28a745",
                                      color: "white",
                                      border: "none",
                                      padding: "5px 10px",
                                      borderRadius: "3px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Add to Order
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>

                <div style={{ flex: 1 }}>
                  <h2>Order Summary</h2>
                  {cart.length === 0 ? (
                    <p>No items in the cart.</p>
                  ) : (
                    <div>
                      {cart.map(item => (
                        <div
                          key={item.id}
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "10px 0",
                          }}
                        >
                          <p>
                            <strong>{item.Name}</strong>
                          </p>
                          <p>Category: {item.Category}</p>
                          <p>Packaging: {item.Packaging}</p>
                          <p>Price: ₹{item.Price}</p>
                          <p>Quantity: {item.quantity}</p>
                          <p>Points: {item.EligiblePoints * item.quantity}</p>
                          <div>
                            <button
                              onClick={() =>
                                decrementQuantity(item.PackagingId)
                              }
                              style={{
                                backgroundColor: "#dc3545",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                                marginRight: "10px",
                              }}
                            >
                              -
                            </button>
                            <button
                              onClick={() =>
                                incrementQuantity(item.PackagingId)
                              }
                              style={{
                                backgroundColor: "#28a745",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </button>
                            <button
                              onClick={() => removeFromCart(item.PackagingId)}
                              style={{
                                backgroundColor: "#ffc107",
                                color: "black",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "3px",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                      <div style={{ marginTop: "20px" }}>
                        <h3>Total Price: ₹{getTotalPrice()}</h3>
                        <h3>Total Eligible Points: {getTotalPoints()}</h3>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  onClick={() => onSubmit(cart)} // Pass a function reference, not the invocation
                  color="primary"
                  className="ms-1"
                >
                  Submit
                </Button>
              </div>
              {/* <AvForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>First Name *</Label>
                      <input
                        className="form-control"
                        name="FirstName "
                        placeholder="Enter First Name"
                        type="text"
                        {...register("FirstName", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.FirstName && "FirstName is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name *</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="LastName"
                        placeholder="Enter Last Name"
                        {...register("LastName", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.LastName && "LastName is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>CountryCode *</Label>
                      <input
                        className="form-control"
                        name="CountryCode"
                        placeholder="Enter Country Code "
                        type="text"
                        {...register("CountryCode", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.CountryCode && "CountryCode is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Contact Number *</Label>
                      <input
                        className="form-control"
                        name="ContactNumber"
                        placeholder="Enter Country Code "
                        type="text"
                        {...register("ContactNumber", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.ContactNumber && "ContactNumber is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Language *</Label>
                      <input
                        className="form-control"
                        name="Language"
                        placeholder="Enter Country Code "
                        type="text"
                        {...register("Language", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.Language && "Language is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <CardTitle tag="h4" className="mb-3">
                  Additional Information
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="DealerType">Dealer Type</Label>
                      <input
                        type="text"
                        {...register("DealerType", { required: true })}
                        placeholder="Enter Dealer Type "
                        className="form-control"
                      />
                      <span className="text-danger">
                        {errors.DealerType && "Dealer Type is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>GST Number</Label>
                      <input
                        className="form-control"
                        name="GSTNumber"
                        type="GSTNumber"
                        {...register("GSTNumber")}
                        placeholder="Enter GSTNumber "
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>PAN Number *</Label>
                      <input
                        className="form-control"
                        name="PAN"
                        type="number"
                        placeholder="Enter PAN"
                        {...register("PAN", {
                          required: true,
                          maxLength: 10,
                        })}
                      />
                      <span className="text-danger">
                        {errors.PAN && "PAN is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <CardTitle tag="h4" className="mb-10">
                  Address Information
                </CardTitle>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="control-Label" htmlFor="Address">
                        Address *
                      </Label>
                      <textarea
                        {...register("Address", { required: true })}
                        className="form-control"
                      />
                      <span className="text-danger">
                        {errors.Address && "Enter Valid Address"}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Country *</Label>
                      <input
                        className="form-control"
                        name="Country"
                        placeholder="Enter country"
                        type="text"
                        {...register("Country", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.Country && "Country is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>District *</Label>
                      <input
                        className="form-control"
                        name="District"
                        placeholder="Enter District"
                        type="text"
                        {...register("District", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.District && "District is required."}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Address Type *</Label>
                      <input
                        className="form-control"
                        name="AddressType"
                        placeholder="Enter AddressType "
                        type="text"
                        {...register("AddressType", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.AddressType && "AddressType is required."}
                      </span>
                    </FormGroup>

                    <FormGroup>
                      <Label>State *</Label>
                      <input
                        className="form-control"
                        name="State"
                        placeholder="Enter State"
                        type="text"
                        {...register("State", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.State && "State is required."}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Label>PostalCode *</Label>
                      <input
                        className="form-control"
                        name="PostalCode"
                        placeholder="Enter PostalCode"
                        type="text"
                        {...register("PostalCode", { required: true })}
                      />
                      <span className="text-danger">
                        {errors.PostalCode && "PostalCode is required."}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="primary" className="ms-1">
                      Submit
                    </Button>{" "}
                    <Button type="reset" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddOrder)
